window.callApi = async function(name, path, params) {
  console.log(`${name} call started...`);
  updateCallInfo(name, "loading");

  $.ajax({
    method:       "GET",
    url:          path,
    contentType:  "application/json",
    timeout:      60000,
    data:         {
      params:       params,
    },
    success:      function(data, status, xhr) {
      updateCallInfo(name, "complete");
      console.log(`${name} call succeeded.`);
    },
    error:        function(data, status, error) {
      updateCallInfo(name, "failed");
      console.log(`${name} call failed.`);
      console.error("Error details:", error);
    }
  });
};

window.callDuckfat = async function(name, path, params) {
  let tops = [100, 500, 1000, 1500, 2000, 2500];

  for (let topValue of tops) {
    let call = tops.indexOf(topValue) + 1;
    let newName = name + `_${call}`;

    console.log(`${newName} call started...`);
    updateCallInfo(newName, "loading");

    let body = { top: topValue, keywords: params.keywords };

    try {
      await callMaigret(path, body);
      console.log(`${newName} call succeeded.`);
      updateCallInfo(newName, "complete");
    } catch (error) {
      console.log(`${newName} call failed.`);
      updateCallInfo(newName, "failed");
    }
  }
};

const callMaigret = (path, params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      method: "GET",
      url: path,
      contentType: "application/json",
      data: { params: params },
      success: function (data, status, xhr) {
        resolve(data);
      },
      error: function (xhr, status, error) {
        reject(error);
      }
    });
  });
};

window.updateCallInfo = async function(name, status) {
  // Complete
  if (status === "complete") {
    $(`tr[name="${name}"]`).find(".call_adv").addClass("complete");
    $(`tr[name="${name}"]`).find(".call_adv").html("complete");
    $(`tr[name="${name}"]`).find(".call_loader").html("");

  // Failed
  } else if (status === "failed") {
    $(`tr[name="${name}"]`).find(".call_adv").addClass("failed");
    $(`tr[name="${name}"]`).find(".call_adv").html("no result");
    $(`tr[name="${name}"]`).find(".call_loader").html("");

  // Loading
  } else {

    const sourceNames = {
      "duckfat": "Scanning popular websites",
      "people": "Social media platforms",
      "db_people": "Online directories",
      "bottin": "Advanced phone research",
      "pamyu": "Web vulnerabilities",
      "mrpeanut": "Top social platforms",
      "moosefat": "Aggregation of digital libraries",
      "phonenumber": "Phone operator analysis",
      "assaut": "Extracting online directories",
      "industries": "Aggregation of social networks"
    }
    
    // Duckfat
    if (name.includes("duckfat")) {
      // 1st call
      if (name === "duckfat_1"){
        $("#call_list").find("tbody").after(`<tr name="${name}">
        <td class="call_name" name="duckfat">${sourceNames["duckfat"]} ${name.slice(-1)}/6</td>
        <td class="call_loader"><div class="loader-line"></div></td>
        <td class="call_status"><span class="call_adv">processing</span></td></tr>`);
      
      // Other calls
      } else {
        $(`td[name="duckfat"]`).last().parent("tr").after(`<tr name="${name}">
        <td class="call_name" name="duckfat">${sourceNames["duckfat"]} ${name.slice(-1)}/6</td>
        <td class="call_loader"><div class="loader-line"></div></td>
        <td class="call_status"><span class="call_adv">processing</span></td></tr>`);
      }

    // Other sources
    } else {
      $("#call_list").find("tbody").append(`<tr name="${name}">
        <td class="call_name" name=${name}>${sourceNames[name]}</td>
        <td class="call_loader"><div class="loader-line"></div></td>
        <td class="call_status"><span class="call_adv">processing</span></td></tr>`);
    }
  }
  return
};