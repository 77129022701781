window.fetchMetaData = async function() {
  const rows  = $("#duckfat_results").find(".results_row").toArray();

  for (let row of rows) {
    let fetched = $(row).hasClass("fetched");

    if (fetched == false){
      $(row).addClass("fetched");
      
      let url = $(row).find(".profile_link").attr("href");

      const urlEncoded = encodeURIComponent(url);
      const apiKey = window.OPENGRAPH_API_KEY;

      const requestUrl = "https://opengraph.io/api/1.1/site/" + urlEncoded + "?app_id=" + apiKey;

      fetchDataFromOpenGraph(requestUrl, row);
    };
  };
};

window.saveMeta = async function(savedResults) {
  // Gathering two duckfat object in one, because the jquery gets us all the tags in an array
 
  if (savedResults.duckfat.length > 1) {

    savedResults.duckfat = [{
                            ...savedResults.duckfat[0],
                            ...savedResults.duckfat[1],
                            ...savedResults.duckfat[2],
                            ...savedResults.duckfat[3],
                            ...savedResults.duckfat[4],
                            ...savedResults.duckfat[5],
                            ...savedResults.duckfat[6]
                            }];

    let metaData = [];
                              
    // Retrieve metadata content from cells and collect it into an object
    $(".meta_data").each(function(index, td) {
      let title = $(td).find(".duckfat__title").text();
      let description = $(td).find(".duckfat__desc").text();
      let mainimg = $(td).find(".duckfat__img_main").attr("src");
      let images = [];

      // Collect the links to the additional images if there are any
      $(td).find(".images_div img").each(function(index,img){
        let link = $(img).attr("src");
        images.push(link);
      });

      let content = {
        title:       title,
        description: description,
        mainimg:     mainimg,
        images:      images
      }

      metaData.push(content);
    })

    // Add metadata to each duckfat object by keys and indexes
    let keys = Object.keys(savedResults.duckfat[0]); // Get the keys in the array
    keys.map((k, index) => savedResults.duckfat[0][k].metaData = metaData[index]); // Iterate through it and add metadata
  };
};

window.fetchDataFromOpenGraph = async (url, row) => {
  let title       = "";
  let description = "";
  let mainImg     = "";
  let imagesArray = [];

  try {
    const response = await fetch(url);
    const data     = await response.json();

    if (data.error && data.error.targetCode == 404) {
      console.log('Error fetching meta data.');
      return;
    } 

  //Cheking if elements exist in the response object
    if (typeof data.openGraph.title !== "undefined"){
      title = data.openGraph.title;
    };

    if (typeof data.openGraph.description !== "undefined"){
      description = data.openGraph.description;
    };

    //Take main picture, if there is no such - add the message
    if (typeof data.openGraph.image !== "undefined"){
      mainImg = `<img src=${data.openGraph.image.url} width='auto' height='52px' class="duckfat__img_main">`;            
    };
    
    //If there are imgs in the array, take the first 10, excluding the first one that is a logo
    if (data.htmlInferred.images.length > 0) {
      imagesArray = data.htmlInferred.images.slice(1,11);
    };

    //Add information to the correct row
    $(row).find(".meta_data").html(`
                            <p class="duckfat__title bold">${title}</p>
                            <p class="duckfat__desc">${description}</p>
                            ${mainImg}
                            <div class="images_div"></div>
                            `);
    
    //Add images from the array to the row if not empty
    if (imagesArray.length > 0) {
      for (let img of imagesArray) {
      $(row).find(".images_div").append(`<img src=${img} class="page_image duckfat__img">`);
      };
    };
  } catch (error) {
    console.error(error);
  }; 
};