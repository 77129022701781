document.addEventListener('DOMContentLoaded', () => {
  var id = $("div.search_option.active").attr("id");
  
  if (id == "option_name") {
    $("#search_info_type_raw_name").prop("checked", true);
  } else if (id == "option_phone") {
    $("#search_info_type_phone").prop("checked", true);
  } else if (id == "option_email") {
    $("#search_info_type_email").prop("checked", true);
  } else if (id == "option_username") {
    $("#search_info_type_username").prop("checked", true);
  } else if (id == "option_hashtag") {
    $("#search_info_type_hashtag").prop("checked", true);
  } else if (id == "option_url") {
    $("#search_info_type_url").prop("checked", true);
  }

  $("div.search_option").click(function() {
    var id = $(this).attr("id");
    $("div.search_option").removeClass("active");
    $(this).toggleClass("active");
    if (id == "option_name") {
      $("#search_info_type_raw_name").prop("checked", true);
    } else if (id == "option_phone") {
      $("#search_info_type_phone").prop("checked", true);
    } else if (id == "option_email") {
      $("#search_info_type_email").prop("checked", true);
    } else if (id == "option_username") {
      $("#search_info_type_username").prop("checked", true);
    } else if (id == "option_hashtag") {
      $("#search_info_type_hashtag").prop("checked", true);
    } else if (id == "option_url") {
      $("#search_info_type_url").prop("checked", true);
    }
  });
});