window.callAWS = async function(params) {
  console.log(`"saved_results/upload" ${params} started...`);
  $.ajax({
    method:       "POST",
    url:          "saved_results/upload",
    data:         {"params": params},
    success:      function(data, status, xhr) {
      console.log(`"saved_results/upload" ${params} call succeeded.`);
      $("#save_button").prop('disabled', false);
      $("#saving").addClass("hidden");
      $("#confirmation").text(`Saved in ${data.case_name}`);
      $("#confirmation").removeClass("hidden");
      setTimeout(()=>{
        $("#confirmation").addClass("hidden");
      }, 2000);
    },
    error:        function(data, status, xhr) {
      console.log(`"osint/upload" ${params} call failed.`);
    }
  });
};

window.checkedObject = async function(checkedSaveResults) {
  // Check if user add at least one element. If not - Save all.
  let allInputs = $(".search_results").find("input:checked").length;

  let coordinates = [];

  if (allInputs) {
    $(".search_results").find("table").each(function(index, table) {
      indexes_checked = [];
      
      // Get the indexes of the selected checkboxes
      $(table).find("input").each(function(index, input){
        if (input.checked == true) {
          indexes_checked.push(index);
        };
      });

      // Get all rows without thead
      let checkedRows = $(table).find("tr").slice(1,-1);
      // Get only checked ones
      let filteredRows = checkedRows.filter((index, element) => indexes_checked.includes(index));
      // Get coordinates
      coordinates = getCoordinates(filteredRows);
      
      // Get the table name
      let tableName = $(table).attr("name");

      const deleteUncheckedResults = (object) => {
        let keys = Object.keys(object);
        for (let k of keys) {
          if (!indexes_checked.includes(keys.indexOf(k))){
            delete object[k];
          };
        };
      };
      
      // Delete unchecked item inside each object
      switch (tableName) {
        case "duckfat":
          if (checkedSaveResults.duckfat.length){
            deleteUncheckedResults(checkedSaveResults.duckfat[0]);
          };
          break;
        case "companies":
        case "officers":
          if (!_.isEmpty(checkedSaveResults.corps[tableName])) {
            deleteUncheckedResults(checkedSaveResults.corps[tableName]);
          }; 
          break;
        case "people":
        case "db_people":
        case "assaut":
          let filteredArray = checkedSaveResults[tableName].filter((person, index) => indexes_checked.includes(index))
          checkedSaveResults[tableName] = filteredArray;
          break;
        case "moosefat":
        case "phonenumber":
          if (indexes_checked.length === 0){
            checkedSaveResults[tableName] = {};
          };
          break;
        case "mrpeanut":
          let keys = Object.keys(checkedSaveResults[tableName])
          let checkedKeys = keys.filter((souce, index) => indexes_checked.includes(index))

          for (source in checkedSaveResults[tableName]) {
            if (!checkedKeys.includes(source)){
              delete checkedSaveResults[tableName][source]
            };
          };
      };
    });
  } else {
    // If no input checked - save coordinates of all addresses
    let allRows = $(".search_results").find("tr");
    coordinates = getCoordinates(allRows);
  };

  // Save coordinates in the object
  checkedSaveResults.coordinates = coordinates;

};

const getCoordinates = (checkedRows) => {

  let coordinates = [];

  $(checkedRows).each(function(index, row){
    let allAddressesInRow = $(row).find(".address");

    const geocoder = new google.maps.Geocoder();

    if (allAddressesInRow.length > 0) {
      $(allAddressesInRow).each(function(index, element) {
        geocoder.geocode({ 'address': $(this).text() }, function (results, status) {
          if (status == 'OK') {
            // Getting the coordinates and adding it to the Array
            let coords_obj = results[0].geometry.location;
            let locPoint = new google.maps.LatLng(coords_obj.lat(), coords_obj.lng());
            coordinates.push(locPoint);
          };
        });
      });
    };
  });

  return coordinates
};